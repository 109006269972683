.ql-editor{
    min-height: 200px !important;
    max-height: 300px;
  }

.ql-toolbar {
    border-radius: 4px ​4px 0 0;
}

.ql-container {
    border-radius: 0 0 4px 4px;
}

.errorQuill .ql-toolbar {
    border-top-color: #ff0000;
    border-left-color: #ff0000;
    border-right-color: #ff0000;
}


.errorQuill .ql-container {
    border-bottom-color: #ff0000;
    border-left-color: #ff0000;
    border-right-color: #ff0000;
}