.leaflet-div-icon {
  background: none !important;
  border: none !important;
}

.cluster-marker {
  color: #FFFFFF;
  font-weight:normal;
  font-size:large;
  background: #ffffffCC;
  border:1px solid #50BCB8;  
  /* box-shadow: 0 0 0 2px #50BCB866;
  outline: 5px solid #50BCB833; */
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cluster-marker-c4g {
  color: #FFFFFF;
  font-weight:normal;
  font-size:large;
  background: #ffffffCC;
  border:1px solid #87ca4f;  
  /* box-shadow: 0 0 0 2px #87ca4f66;
  outline: 5px solid #87ca4f33; */
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}